import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { init } from 'project-common-mobile'
// 页面初始化
init(router)

import { ActionSheet, Empty } from 'vant'

localStorage.setItem('base_token', '')

createApp(App).use(store).use(ActionSheet).use(Empty).use(router).mount('#app')
