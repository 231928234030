var map = new Map()

export function startForResult(key) {
  let params = map.get(key)

  if (!params) {
    params = {}
  }
  params.pid = new Date().getTime()
  params.asyncPid = 0

  map.set(key, params)
}

export function setResult(key, data) {
  let params = map.get(key)
  if (params) {
    params.asyncPid = params.pid
    params.data = data
  }

  return null != params
}

export function getResult(key) {
  let params = map.get(key)
  if (params) {
    if (params.pid == params.asyncPid) {
      return params.data
    }
    return null
  }
  return null
}

export function keepResult(key) {
  let params = map.get(key)

  if (params && params.data) {
    params.asyncPid = params.pid
  }
}

export function clear(key = '') {
  if (key == '') {
    map.clear()
  } else {
    map.delete(key)
  }
}
