import { getCurrentInstance } from 'vue'

var router
var pid = 0
var preparePid = null
var dataModel = new Map()
var pageStack = []
var fnInvokeMap = new Map()
var pageScrollMap = new Map()

var currentPage = ''
window.addEventListener(
  'scroll',
  function () {
    let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    // console.log('addEventListener',e.target.scrollTo+getCurrentPage()+":"+top)
    if (top || top >= 0) {
      pageScrollMap.set(getCurrentPage(), top)
    }
  },
  true
)

/**
 * 页面
 * @param {*} fn 此方法 前进刷新后退不刷新
 * @param {*} data 保活数据
 * @param {*} tabIndex 是tab 填入tab下标
 * @returns
 */
export const page = (fn, data, tabIndex = -2) => {
  let call = false
  if (preparePid) {
    // 创建新的页面
    let pageId = `${preparePid}__pid__#${router.currentRoute.value.path}#`
    pageStack.push(pageId)
    dataModel.set(pageId, data)
    // 消费preparePid
    preparePid = null
    call = true
    currentPage = pageId
    const { ctx } = getCurrentInstance()

    // console.log('ctx', ctx)

    if (ctx) {
      window.scrollTo(0, 0)
    }
    // printPageStack()
  } else {
    let cPage = ''
    if (tabIndex == -2) {
      // 这里说明走了回退分支
      cPage = pageStack[pageStack.length - 2]
    } else {
      cPage = pageStack[tabIndex + 1]
    }

    currentPage = cPage

    // 获取旧数据
    let oldData = dataModel.get(cPage)
    // console.log('++++++++')
    // console.log('cPage',cPage)
    // console.log('oldData',oldData)
    if (oldData) {
      Object.keys(oldData).forEach((key) => {
        data[key] = oldData[key]
      })
      dataModel.set(cPage, data)
    } else {
      call = true
      if (tabIndex != -2) {
        dataModel.set(cPage, data)
      }
    }

    let scrollY = pageScrollMap.get(getCurrentPage())
    // console.log('scrollY',getCurrentPage())
    // console.log('scrollY',pageScrollMap)
    // console.log('scrollY',scrollY)
    // console.log('currentPage', currentPage + scrollY)
    if (scrollY) {
      const { ctx } = getCurrentInstance()
      if (ctx) {
        setTimeout(() => {
          window.scrollTo(0, scrollY)
        }, 100)
      }
    }
  }

  // 调用方法
  let fns = fnInvokeMap.get(getCurrentPage())
  fnInvokeMap.delete(getCurrentPage())
  return function () {
    if (call) {
      fn.call(this, arguments)
    }
    if (fns) {
      const { ctx } = getCurrentInstance()
      if (ctx) {
        try {
          for (let i = 0; i < fns.length; i++) {
            let method = fns[i]
            ctx[method.name].call(ctx, method.args)
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
}

const finishPage = () => {
  console.log('finishPage')
  // 删除最后一个页面
  let cPage = pageStack[pageStack.length - 1]
  pageScrollMap.delete(cPage)
  // 返回监听
  dataModel.delete(cPage)
  pageStack.splice(pageStack.length - 1, 1)
}
/**
 * 初始化页面
 * @param {*} rt router
 * @param {*} pages 首页tab 缓存页面最少2个或0
 */
export const init = (rt, pages = []) => {
  window.addEventListener(
    'popstate',
    function () {
      // 这个方法会在返回完成后回调
      finishPage()
    },
    false
  )

  // 跟路径
  let pageId = `${0}__pid__#/#`
  pageStack.push(pageId)
  pid++

  router = rt
  pid = pages.length
  if (pid > 1) {
    for (let i = 0; i < pages.length; i++) {
      let pageId = `${i}__pid__#${pages[i]}#`
      pageStack.push(pageId)
    }
  } else {
    preparePid = ++pid
  }
}

/**
 * 跳转新页面 router.push
 * @param {*} obj
 */
export const navigateTo = (obj) => {
  preparePid = ++pid
  if (obj) {
    router.push(obj)
  }
}
/**
 * 回退
 */
export const navigateBack = () => {
  router.back()
}
/**
 * 替换
 * @param {*} obj
 */
export const redirectTo = (obj) => {
  finishPage()
  preparePid = ++pid
  router.replace(obj)
}

/**
 * 获取页面堆栈
 * @returns
 */
export const getCurrentPages = () => {
  return pageStack
}
/**
 * 获取当前页面
 * @returns
 */
export const getCurrentPage = () => {
  return currentPage
}
/**
 * 执行其他页面方法
 * @param {*} pageId
 * @param {*} name
 * @param  {...any} args
 */
export const methodInvoke = (pageId, name, ...args) => {
  let fns = fnInvokeMap.get(pageId)
  if (!fns) {
    fns = []
  }
  fns.push({
    name: name,
    args: args,
  })
  fnInvokeMap.set(pageId, fns)
}

export const printPageStack = () => {
  console.log('fnInvokeMap', fnInvokeMap)
  console.log('pageStack', pageStack)
  console.log('preparePid', preparePid)
  console.log('dataModel', dataModel)
}
