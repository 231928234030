import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    // 验房报告
    path: '/property/inspectionreport',
    name: 'inspectionreport',
    meta: { title: '验房报告' },
    component: () => import('../views/InspectionReport.vue'),
  },

  {
    // 视频预览
    path: '/videoPreview',
    name: 'videoPreview',
    meta: { title: '视频预览' },
    component: () => import('../views/VideoPreview.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = ''
  }

  //Authorization Community-Id

  // token
  let authorization = to.query.Authorization
  // 社区id
  let communityId = to.query.CommunityId

  if (authorization) {
    localStorage.setItem('token', authorization)
  }

  if (communityId != undefined) {
    localStorage.setItem('communityId', authorization)
  }

  next()
})
export default router
